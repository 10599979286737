/* Override react-calendar default styles */
.react-calendar {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  color: white !important;
}

.react-calendar__tile {
  color: white !important;
  position: relative !important;
  padding-bottom: 20px !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-calendar__tile--active {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.react-calendar__tile--now {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-calendar__navigation button {
  color: white !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-calendar__month-view__weekdays {
  color: white !important;
}

.event-marker {
  width: 8px;
  height: 8px;
  background-color: #f63b3b;
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
} 